import {FaFacebook, FaInstagram, FaSpotify, FaTwitter, FaYoutube} from "react-icons/fa"
import React from "react"
import {SiTiktok} from "react-icons/si";

const Social = () => (
  <div className="flex justify-center items-center">
    <a className="mx-3 text-2xl" href="https://open.spotify.com/artist/58E6TLHwF0cHdkrLd5iNRh?si=wIMBSlyASfOifi94GOTIQA" target="_blank" rel="noreferrer"><FaSpotify/></a>
    <a className="mx-3 text-2xl" href="https://www.youtube.com/channel/UCA-BybeK2bLrG-bvgfjZI0A" target="_blank" rel="noreferrer"><FaYoutube/></a>
    <a className="mx-3 text-2xl" href="https://www.instagram.com/mynameis_jendrik/" target="_blank" rel="noreferrer"><FaInstagram/></a>
    <a className="mx-3 text-2xl" href="https://www.tiktok.com/@mynameis_jendrik" target="_blank" rel="noreferrer"><SiTiktok/></a>
    <a className="mx-3 text-2xl" href="https://www.facebook.com/Jendriik" target="_blank" rel="noreferrer"><FaFacebook/></a>
    <a className="mx-3 text-2xl" href="https://twitter.com/Jendrikkkk" target="_blank" rel="noreferrer"><FaTwitter/></a>
  </div>
)

Social.propTypes = {
}

export default Social
