import React from "react";
import {FaTimes} from "react-icons/fa";
import PropTypes from "prop-types"


const Modal = ({hidden, children, onDismiss}) => {
  return !hidden && (
    <>
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-10" onClick={onDismiss}/>
      <div className="fixed text-center
      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
      backdrop-filter backdrop-blur-sm z-20 rounded-lg border border-white py-8 px-16" role="dialog">
        {children}
        <button className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 h-8 w-8 text-lg p-0
        bg-white text-black flex items-center justify-center rounded-full"
                onClick={onDismiss} tabIndex={0} aria-label="Close"><FaTimes/></button>
      </div>
    </>
  );
}

Modal.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func.isRequired
}

Modal.defaultProps = {
  hidden: true
}

export default Modal;
