import * as React from "react"
import Layout from "../components/layout";
import Email from "../components/email";
import Social from "../components/social";
import {Link} from "gatsby";
import {useState} from "react";
import {isValidEmail} from "../utils/validation";
import Modal from "../components/modal";
import Website from "../components/website";

const ContactPage = () => {

  const [state, setState] = useState({
    form: {
      name: "",
      email: "",
      message: ""
    },
    modal: {
      title: "",
      message: "",
      hidden: true
    }
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleDismiss = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        hidden: true
      }
    });
  }

  const handleSubmit = (event) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...state.form })
    })
      .then(response => {
        if (response.ok) {
          return true;
        } else {
          throw response.statusText;
        }
      })
      .then(() => {
        setState({
          modal: {
            hidden: false,
            title: "Thank you ❤️",
            message: "Unless you sent hate. Then we're just sorry..."
          },
          form: {
            name: "",
            email: "",
            message: ""
          }
        });
      })
      .catch(err => setState({
        ...state,
        modal: {
          hidden: false,
          title: 'Something went wrong 🙈',
          message: "Please don't feel hate. Just try again."
        },
      }))

    event.preventDefault();
  }

  const handleChange = (event) => {
    setState({
      ...state,
      form: {
        ...state.form,
        [event.target.name]: event.target.value }
      }
    );
  }

  const isFormValid = () => {
    return isValidEmail(state.form.email) && state.form.name && state.form.message;
  }

  return (
    <>
      <Layout isBlurred title="Contact">
        <h1 className="text-7xl">Contact</h1>
        <form onSubmit={handleSubmit}
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field">
          <div className="flex flex-col mb-6">
            <input name="name" inputMode="text" placeholder="Name" className="mb-2" required
                   value={state.form.name} onChange={handleChange}/>
            <input name="email" inputMode="email" placeholder="Email" className="mb-2" required
                   value={state.form.email} onChange={handleChange}/>
            <div className="mb-2">
            <textarea
              name="message" inputMode="text" placeholder="Hi Jendrik, I wanted to contact you to..." required
              className="h-40"
              value={state.form.message} onChange={handleChange}/>
            </div>
            <button type="submit" className="mb-2" disabled={!isFormValid()}>Submit</button>
          </div>
        </form>
        <Social/>
        <div className="flex flex-row justify-center my-6">
          <Link to="/">
            <button className="mr-2">Home</button>
          </Link>
          <Link to="/about">
            <button>About</button>
          </Link>
        </div>
          <h2 className="text-xl font-bold">Management</h2>
          <p>
            <Email email="management@jendriksworld.com"/>
          </p>
      </Layout>
      <Modal hidden={state.modal.hidden} onDismiss={handleDismiss}>
        <h1>{state.modal.title}</h1>
        <p className="text-xl">{state.modal.message}</p>
      </Modal>
    </>
  )
}

export default ContactPage
